<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>{{ $t('usuario') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="usuarioRequest.status"
                        :label="$t('ativar')"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="usuarioRequest.usuarioInterno"
                        :label="$t('usuarioInterno')"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="usuarioRequest.receberNotificacaoEmail"
                        :label="$t('receberNotificacaoEmail')"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('perfil') }}</label>
                    <v-combobox
                        v-model="usuarioRequest.perfilSelected"
                        :items="listPerfil"
                        item-text="nome"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    v-if="usuarioRequest.usuarioInterno == 0 || usuarioRequest.usuarioInterno == null || usuarioRequest.usuarioInterno == undefined"
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('distribuidor') }}</label>
                    <v-combobox
                        v-model="usuarioRequest.distribuidorSelected"
                        :items="listDistribuidor"
                        item-text="nameAndCnpj"
                        item-value="cnpjProcessed"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('primeiroNome') }}</label>
                    <v-text-field
                        v-model="usuarioRequest.primeiroNome" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('ultimoNome') }}</label>
                    <v-text-field
                        v-model="usuarioRequest.ultimoNome" 
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('email') }}</label>
                    <v-text-field
                        v-model="usuarioRequest.email"
                        :rules=[validations.required,validations.email]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('telefone') }}</label>
                    <v-text-field
                        v-model="usuarioRequest.telefone" 
                        :rules=[validations.required]
                        v-mask="'+55 (##) #####-####'"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    v-if="id == 0"
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('novaSenha') }}</label>
                    <v-text-field
                        :append-icon="showSenha ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showSenha ? 'text' : 'password'"
                        v-model="senha"
                        autocomplete="new-password"
                        :rules=[validations.password]
                        hint="Senha deve conter pelo menos 8 caracteres, 1 letra maiúscula e 1 letra minúscula"
                        outlined
                        single-line
                        loading
                        @click:append="showSenha = !showSenha"
                    >
                        <template v-slot:progress>
                            <v-progress-linear
                                :value="progress"
                                :color="color"
                                absolute
                                height="7"
                                >
                            </v-progress-linear>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col 
                    v-if="id == 0"
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('confirmarSenha') }}</label>
                    <v-text-field
                        :append-icon="showConfirmarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmarSenha ? 'text' : 'password'"
                        v-model="confirmarSenha"
                        autocomplete="new-password"
                        :rules=[validations.samePassword(senha,confirmarSenha)]
                        single-line
                        outlined
                        @click:append="showConfirmarSenha = !showConfirmarSenha"
                    ></v-text-field>
                </v-col>
            </v-row>

            <br />

            <ActionButtons
                :request="usuarioRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required, validEmail, validPassword } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: vue => ({

            validForm: true,

            usuarioRequest: {
                id: 0,
                perfilSelected: null,
                distribuidorSelected: null,
                idPerfil: 0,
                primeiroNome: "",
                ultimoNome: "",
                email: "",
                senha: "",
                phone: "",
                status: 1,
                usuarioInterno: 1,
                receberNotificacaoEmail: 1,
            },

            listPerfil: [],
            listDistribuidor: [],

            loading: false,
            senha: "",
            confirmarSenha: "",
            showSenha: false,
            showConfirmarSenha: false,

            validations: {
                required: required,
                email: validEmail,
                senha: validPassword,
                samePassword (senha, confirmarSenha) {
                    if (senha !== confirmarSenha) {
                        return vue.$t('senhasNaoConferem')
                    }
                    else
                        return true;
                }
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {

            progress () {
                return Math.min(100, this.senha.length * 13)
            },

            color () {
                return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
            },
        },

        methods: {

            validEmailAndPassword() {

                var isValidEmail = this.usuarioRequest.email !== "" && validEmail(this.usuarioRequest.email);

                var isValidPass = true;

                if (this.senha != "" || this.confirmarSenha != "")
                {
                    isValidPass = validPassword(this.senha) === true && this.senha === this.confirmarSenha;
                }

                var isValidFrm = isValidEmail === true && isValidPass === true;

                return isValidFrm;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("usuarioModule/GetById", this.id);

                    this.usuarioRequest = response;
                    this.usuarioRequest.perfilSelected = response.perfil;
                }
            },

            async getLists() {
                
                this.listPerfil = await this.$store.dispatch("perfilModule/List");
                this.listDistribuidor = await this.$store.dispatch("empresaModule/ListDistribuidor");
            },

            async cancel() {
                this.$router.push({ path: "/admin/usuarioList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false || this.validEmailAndPassword() === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    this.usuarioRequest.id = this.id;
                    this.usuarioRequest.password = this.senha;
                    this.usuarioRequest.confirmPassword = this.confirmarSenha;
                    this.usuarioRequest.idPerfil = this.usuarioRequest.perfilSelected.id;

                    if (this.usuarioRequest.status === null) {
                        this.usuarioRequest.status = 0;
                    }

                    if (this.usuarioRequest.usuarioInterno === null) {
                        this.usuarioRequest.usuarioInterno = 0;
                    }

                    if (this.usuarioRequest.receberNotificacaoEmail === null) {
                        this.usuarioRequest.receberNotificacaoEmail = 0;
                    }

                    if (this.usuarioRequest.usuarioInterno == 0) {
                        this.usuarioRequest.idDistribuidor = this.usuarioRequest.distribuidorSelected.id;
                    }

                    const result = await this.$store.dispatch("usuarioModule/CreateUpdate", this.usuarioRequest);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.$router.push({ path: "/admin/usuarioList" });
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>
